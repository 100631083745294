import * as React from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: true,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
  activeCollection: null,
  updateActiveCollection: () => {},
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [activeCollection, setActiveCollection] = React.useState(
    defaultValues.activeCollection
  )
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(true)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            setLoading(false)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
      setLoading(false)
    }

    initializeCheckout()
  }, [])

  const klaviyoTrackAddToCart = (cart, product, variant, quantity) => {
    try {
      window._learnq.push([
        "track",
        "Added to Cart",
        {
          $value: cart.lineItemsSubtotalPrice.amount,
          AddedItemProductName: product.title,
          AddedItemProductID: variant.storefrontId,
          AddedItemSKU: variant.sku,
          AddedItemCategories: product.collections.map((c) => c.handle),
          AddedItemImageURL: variant.image.originalSrc,
          AddedItemURL: `https://www.portandbay.com/products/${product.handle}`,
          AddedItemPrice: variant.price,
          AddedItemQuantity: quantity,
          ItemNames: cart.lineItems.map((item) => item.title),
          CheckoutURL: cart.webUrl,
          Items: cart.lineItems.map((item) => {
            return {
              ProductID: item.id,
              SKU: item.variant.sku,
              ProductName: item.title,
              Quantity: item.quantity,
              ItemPrice: item.variant.price,
              RowTotal: item.quantity * item.variant.price,
              ProductURL: `https://www.portandbay.com/products/${item.variant.product.handle}`,
              ImageURL: item.variant.image.src,
              ProductCategories: [],
            }
          }),
        },
      ])
    } catch (err) {
      console.log(err)
    }
  }

  const updateShippingSurcharge = (res) => {
    /*
    PB has an oversize shipping surcharge for some bulky items
    There are six tiers of pricing, which correspond to six variants
    The tiers are triggered on items with a weight
    Weights correspond to the tiers - so 1 pound item needs the oversized-tier-1 variant added to cart
    Each variant sku is named like: 'oversized-tier-1' 
    */

    //below corresponds to the six tiers of oversized surcharge and will hold quantity of each
    let perTierQty = [0,0,0,0,0,0];

    //check if any line items are oversize by looking for weight and update quantity array if so
    res.lineItems.forEach((item) => {
      //array is zero-index, but tiers start at 1, thus the discrepancy below
      if (item.variant.weight && item.variant.weight === 1) {
        perTierQty[0] = perTierQty[0] + item.quantity
      }
      else if (item.variant.weight && item.variant.weight === 2) {
        perTierQty[1] = perTierQty[1] + item.quantity
      }
      else if (item.variant.weight && item.variant.weight === 3) {
        perTierQty[2] = perTierQty[2] + item.quantity
      }
      else if (item.variant.weight && item.variant.weight === 4) {
        perTierQty[3] = perTierQty[3] + item.quantity
      }
      else if (item.variant.weight && item.variant.weight === 5) {
        perTierQty[4] = perTierQty[4] + item.quantity
      }
      else if (item.variant.weight && item.variant.weight === 6) {
        perTierQty[5] = perTierQty[5] + item.quantity
      }
    })

   //any existing surcharges ordered in array by tier
   const existingSurcharges = []
    existingSurcharges[0]  = res.lineItems.find(
      (item) =>
        item.variant.sku ===
        "oversized-tier-1"
    )
    existingSurcharges[1]  = res.lineItems.find(
      (item) =>
        item.variant.sku ===
        "oversized-tier-2"
    )
    existingSurcharges[2]  = res.lineItems.find(
      (item) =>
        item.variant.sku ===
        "oversized-tier-3"
    )
    existingSurcharges[3]  = res.lineItems.find(
      (item) =>
        item.variant.sku ===
        "oversized-tier-4"
    )
    existingSurcharges[4]  = res.lineItems.find(
      (item) =>
        item.variant.sku ===
        "oversized-tier-5"
    )
    existingSurcharges[5]  = res.lineItems.find(
      (item) =>
        item.variant.sku ===
        "oversized-tier-6"
    )

    //again using an array for each tier referenced by location in array
    //EX: tier 1 id == surchargeVariantIds[0]
    const surchargeVariantIds = [
      'gid://shopify/ProductVariant/43982249328835',
      'gid://shopify/ProductVariant/43982248214723',
      'gid://shopify/ProductVariant/43982251163843',
      'gid://shopify/ProductVariant/43982251196611',
      'gid://shopify/ProductVariant/43982251229379',
      'gid://shopify/ProductVariant/43982251262147',
    ]

    //the way that updateShippingSurcharge() is called, we will only be dealing with one tier at a time
    //should be no need to handle multiple async calls
    //really just about finding which tier has changed (in the event that there are multiple diff tier itemss in cart - which is edge case)
    perTierQty.forEach((qty, index) => {
      if(qty !== existingSurcharges[index]?.quantity){
        makeUpdate(existingSurcharges[index], qty, surchargeVariantIds[index])
      }
      return true
    })

    function makeUpdate(surchargeLineItem, oversizeItemQty, variantId){
      if (surchargeLineItem) {
        //already a surcharge item, so update or remove
        if (oversizeItemQty) {

          return client.checkout
            .updateLineItems(checkout.id, [
              { id: surchargeLineItem.id, quantity: oversizeItemQty },
            ])
            .then((response) => {
              setCheckout(response)
              setLoading(false)
            })
        } else {
          return client.checkout
            .removeLineItems(checkout.id, [surchargeLineItem.id])
            .then((response) => {
              setCheckout(response)
              setLoading(false)
            })
        }
      } else if (oversizeItemQty) {
        //not already a surcharge item in cart, so add one
        return client.checkout
          .addLineItems(checkout.id, [
            {
              variantId,
              quantity: oversizeItemQty,
            },
          ])
          .then((response) => {
            setCheckout(response)
            setLoading(false)
          })
      }
    }
  }

  const addVariantToCart = (variantId, quantity, product, variant) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        klaviyoTrackAddToCart(res, product, variant, quantity)
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        updateShippingSurcharge(res)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const updateActiveCollection = (handle) => {
    setActiveCollection(handle)
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        updateShippingSurcharge(res)
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        updateShippingSurcharge(res)
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        updateActiveCollection,
        activeCollection,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
